<template>
        
    <div class="row">
        <!-- Left Side -->
        <div class="col-12 col-sm-5 col-md-5 col-lg-5">
            <div class="row h-40">
                <div class="col-md-5 d-flex align-items-center">
                    <span> Select Contact Profile: </span>
                </div>

                <div class="col-md-7">
                    <AsyncSelect
                        placeholder="Contact Profile"
                        v-model="patient"
                        :api-service="fetchContactProfiles"
                        :format-label="formatPatientLabel"
                        :disabled="isDisabled"
                    />
                </div>
            </div>

            <div class="row h-40">
                <div class="col-md-5 d-flex align-items-center">
                    <span> Phone No: </span>
                </div>

                <div class="col-md-7">
                    <span> <strong> {{patientMobile && patientMobile.value}} </strong> </span>
                </div>
            </div>

          <div class="row h-40">
            <div class="col-md-5 d-flex align-items-center">
              <span> Ship from (Warehouse): </span>
            </div>

            <div class="col-md-7">
              <v-select
                  placeholder="Select Warehouse Location"
                  v-model="formData.location_id"
                  :options="locations"
                  label="text"
                  :reduce="text => text.id"
              />
            </div>
          </div>

        </div>

        <div class="col-md-2"></div>

        <!-- Right Side -->
        <div class="col-12 col-sm-5 col-md-5 col-lg-5">

            <div class="row h-40 mb-1">
                <div class="col-md-5 d-flex align-items-center">
                    <span> Return Date: </span>
                </div>
                <div class="col-md-7">
                    <input
                        class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                        tabindex="1"
                        type="date"
                        placeholder=""
                        v-model="formData.date"
                    >
                </div>
            </div>

            <div class="row h-40 mb-1">
                <div class="col-md-5 d-flex align-items-center">
                    <span> Invoice Date: </span>
                </div>
                <div class="col-md-7">
                    <input
                        class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                        tabindex="1"
                        type="date"
                        placeholder=""
                        v-model="formData.invoice_date"
                        :disabled="isDisabled"
                    >
                </div>
            </div>

            <div class="row h-40 mb-1">
                <div class="col-md-5 d-flex align-items-center">
                    <span> Invoice No: </span>      
                </div>
                <div class="col-md-7">
                    <div class="d-flex align-items-center justify-content-md-end mb-1">
                        <div class="input-group input-group-merge invoice-edit-input-group">
                            <div class="input-group-text" :style="{background: isDisabled ? '#efefef' : 'transparent'}">
                                <span>{{ prefix }}-</span>
                            </div>
                            <input
                              type="number"
                              min="1"
                              class="form-control invoice-edit-input"
                              placeholder="Invoice Number"
                              v-model="serial"
                              :disabled="isDisabled">
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>

</template>

<script setup>
import {computed, inject, onMounted, ref, watch} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import handlePurchase from '@/services/modules/purchase'
import handleCBusinesses from '@/services/modules/businesses'
import handleBusinessesLocations from '@/services/modules/businessesLocations'
import handleContact from '@/services/modules/contact'
import handleCostCentres from "@/services/modules/ngo/costCentre";
import handleProjects from "@/services/modules/procurement/project";
import {editVoucherNumber} from "@/services/utils/voucherNumberGenerator";
import handleHospitalBilling from "@/services/modules/hospital/billing";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";
import handleInventory from "@/services/modules/inventory";

const props = defineProps({
  addNewItem: {
    type: Function
  },
  products: {
    type: Array,
    default: []
  },
  tableItems: {
    type: Array
  },
  isDisabled: {
    type: Boolean,
    default: false
  }
})

const $route = useRoute();
const $router = useRouter();
const patient = ref(null)
const companyId = $route.params.companyId
const companyQuery = `?company_id=${companyId}`;
const selectedData = ref({});
const formData = inject('formData');
const showError = inject('showError')
let prefix = ref('');
let serial = ref('');
let loader =ref(false);
let locations = ref([]);
const product_id = ref(null);
const additionalQuery = {
    type: "patient"
}
const additionalProductQuery = {
    product_type: "services"
}

const {fetchProductList} = handlePurchase()
const {getProduct} = handleInventory()
const {fetchBusinessList} = handleCBusinesses()
const {fetchBusinessLocationsList} = handleBusinessesLocations()
const {fetchContactProfiles, fetchSingleContactProfile} = handleContact()
const { fetchProjects } = handleProjects()
const { fetchCostCentreList } = handleCostCentres();
const { fetchIpdRegisterList } = handleHospitalBilling()
const { formatPatientLabel } = useAsyncDropdownHelper()

//computed

const patientMobile = computed(() => {
    if (selectedData.value.profile_items && selectedData.value.profile_items.length) {
        return selectedData.value.profile_items.find(item => item.field === 'mobile_no')
    }
    return null
})

const patientId = computed(() => {
    if (
        selectedData.value.company_roles &&
        selectedData.value.company_roles.length &&
        selectedData.value.company_roles[0].pivot
    ) {
        return selectedData.value.company_roles[0].pivot.serial_no
    }
    return null
})

const billNumber = computed(() => formData.value.bill_number)
const contact = computed(() => formData.value.contact)

watch(billNumber, (newValue) => {
  if(newValue) {
    let voucher = editVoucherNumber(newValue)
    prefix.value = voucher.prefix;
    serial.value = voucher.serial;
  }
})

watch(contact, (newValue) => {
  if (newValue) {
    patient.value = {
      id: newValue.id,
      name: newValue.name
    }
  }
})

watch(patient, () => {
    const patientQuery = companyQuery + '&type=patient';
    formData.value.contact_profile_id = patient.value.id;
    fetchSingleContactProfile(patient.value.id, patientQuery).then(res => {
        if (res.data) {
            selectedData.value = res.data;
        }
    })
})

watch(product_id, async () => {
    const productResp = await getProduct({
        id: product_id.value,
        company_id: companyId
    })
    if (!productResp.data) {
        showError("Something went wrong please try again");
        return;
    }
    let selected = productResp.data;
    let product = {
        name: selected.name
    }
    props.tableItems.push({
        id: selected.id,
        product_id: product_id.value,
        name: selected.name,
        description: selected.description.unit.description,
        quantity: 1,
        rate: selected.description.sales_price,
        discount: 0,
        vat: selected.description.vat_rate,
        sub_total: 0,
        discount_amount: 0,
        discount_percent: 0,
        vat_amount: 0,
        total: 0,
        isEdit: false,
        product: product
    })
})

onMounted(async () => {
  const companyQuery = `?company_id=${companyId}`;
  const businessLocationRes = fetchBusinessLocationsList(companyQuery)

  Promise.all([
    businessLocationRes.then(res=> {
      if(res.data) locations.value = res.data
    }),
  ])
      .then(() => {
        loader.value = false
      })
      .catch(() => {
        loader.value = false
      })
})

</script>

<style>
.select label{
    font-size: 14px;
}
.h-40{
    height: 40px;
    align-items: center;
}
</style>